<template>
  <div
    :class="{
      'app-section': true,
      'without-margins': withoutMargins,
    }"
  >
    <v-container :class="contentClass" :fluid="!appDisplay.isBigScreen.value">
      <slot />
    </v-container>
  </div>
</template>

<script setup lang="ts">
import { useAppDisplay } from '@/lib/style'
const appDisplay = useAppDisplay()

withDefaults(
  defineProps<{
    withoutMargins?: boolean
    contentClass?: string | object
  }>(),
  {
    withoutMargins: false,
    contentClass: () => ({}),
  },
)
</script>

<style lang="scss" scoped>
.app-section:not(.without-margins) {
  margin-top: 6svw;
  margin-bottom: 6svw;
}
</style>
